

.navDropDown-toggle-sub #basic-nav-dropdown {
    font-size: 1rem !important;
    color: #d2c0c0 !important
}


#mobile-dropdown-menu {
    color: #212529 !important;
    font-size: 1rem !important;
    padding-left: 16px;
    text-decoration: none;
}

.font-weight-400 .dropdown-toggle  {
    font-weight: 400 !important;
}

#mobile-dropdown-menu::after {
    margin-left: 6px !important;
    vertical-align: 0px !important;
    content: "";
    border-top: 9px solid !important;
    border-right: 6px solid transparent !important;
    border-bottom: 0;
    border-left: 6px solid transparent !important;
}

.nav-link.active {
    font-weight: bold;
    color: whitesmoke !important; /* Change this to your desired active color */
  }

  .active-dropdown #basic-nav-dropdown {
    font-weight: bold;
    color: whitesmoke !important; /* Change this to your desired active color */
  }

  .no-color #basic-nav-dropdown {
    color: rgba(0,0,0,.5) !important;
}

.active-bold {
    font-weight: bold !important;
    color: rgba(0,0,0,.5) !important;
}

.dropdown-item.active {
    font-weight: bold;
}

.active-dropdown .dropdown-toggle  {
    font-weight: 600 !important;
}

.active-nav {
    font-weight: 600 !important;
    background-color: unset !important;
}

.navbar-collapse.show {
    background-color: unset !important;
}

.dropdown-menuitems {
    white-space: nowrap !important;
    font-weight: 400;
    text-decoration: none !important;
}


/* media queries for small screens */

@media (max-width: 767px) {
    .it-service {
      padding: 0px 16px 16px 16px !important;
    }
  }

  @media (min-width: 1700px) {
    .paraText {
        padding-left: 80px !important;
        padding-right: 80px !important;
    }
  }

