html {
  /* this makes sure the padding and the border is included in the box sizing */
  box-sizing: border-box;
  overflow-x: hidden;
}

html body {
  padding: 0;
  margin: 0;
  overflow-x: inherit;
}

p {
  font-size: 20px;
  line-height: 35px;
}

.contact-btn {
  width: fit-content;
  font-size: 1.2em;
  font-weight: 500;
  color: #092a49;
  border-color: #092a49;
}

.contact-btn:hover {
  background-color: lightgray;
}

.navbar {
  z-index: 99;
  position: fixed;
  top: 0;
  width: 100%;

}
.navbar.scrolled{
  background-color: white;
  z-index: 99;
  position: fixed;
  top: 0;
  width: 100%;
  box-sizing: border-box;
  box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
}

.scrolled .brand-logo{
  width: 70px;
}
.navbar-toggler {
  border-color: white;
}

.brand-logo {
  width: 130px;
}

.offerings-para {
  display: block;
  overflow: hidden;
  margin-bottom: 7%;
  font-size: 2.6em;
  font-weight: 500;
}

.pages-navbar {
  background-color: #092a49;
  position: relative;
  top: 0;
  width: 100%;
}

.pages-navbar-md {
  background-color: #092a49;
  width: 100%;

  img {
    height: 100%;
  }
}

.pages-nav {
  color: white;
}

.page-enter {
  opacity: 0.01;
  transform: translateX(-100%);
}

.page-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: all 300ms ease-in;
}

.page-exit {
  opacity: 1;
  transform: translateX(0%);
}

.page-exit-active {
  opacity: 0.01;
  transform: translateX(100%);
  transition: all 300ms ease-out;
}

.home-div {
  padding: 0;
  /* position: relative; */
  overflow: hidden;
}

.home-heading {
  position: absolute;
  /* top: 196px; */
  top: 240px;
  left: 160px;
  color: white;
  font-weight: 500;
  font-size: 70px;
  width: 30%;
}

.home-unlocked {
  position: absolute;
  /* top: 270px; */
  top: 320px;
  left: 160px;
  color: white;
  font-weight: 500;
  font-size: 70px;
  width: 30%;
}

.home-para {
  position: absolute;
  /* top: 360px; */
  top: 440px;
  left: 160px;
  color: white;
  font-weight: 300;
  font-size: 20px;
  width: 30%;
}

.home-cs {
  font-size: 35px;
  color: #092a49;
  font-weight: 700;
}

.our-impact {
  font-size: 3em; 
  font-weight: 700; 
  color: white;
  margin-top: 10%; 
  margin-left: 40%;
}
.how-help {
  font-size: 2.5em;
}
.our-para {
  font-size: 1.3em; 
  font-weight: 400; 
  color: white; 
  margin-left: 40%;
  margin-top: 40px;
}

.our-image {
  object-fit: contain;
  /* width: 35%; */
  width: 50%;
  /* width: 60%; */
  /* height: 60%; */
  margin-top: 40px;
  margin-bottom: 40px;
  /* margin-top: 3% !important; */
}

.our-learn-more {
  border-color: white !important;
  color: white !important;
  margin-left: 30%;
}
.our-learn-more:hover {
  border-color: gray !important;
  color: gray !important;
}

.home-fact {
  font-size: 1em;
  font-weight: 700; 
  color: #00afef;
}

.circle {
  position: relative;
  /* display: flex; */
  width: 300px;
  height: 300px;
}

.circle-text {
  position: absolute;
  top: 75px;
  left: 100px;
}

.home-qp {
  font-size: 3em;
  font-weight: 700;
  color: #00afef;
}

.home-links {
  text-decoration: none;
  color: #092a49;
  border-bottom: 3px solid #092a49;
  padding-bottom: 2px;
  font-size: 1.4em;
  font-weight: 500;
}

.home-links:hover {
  text-decoration: none;
  color: #00afef !important;
  border-bottom: 3px solid #00afef;
  padding-bottom: 2px;
}

.pages-div {
  padding: 0;
  max-height: 60vh;
  overflow: hidden;
}

.pages-end {
  font-size: 3em;
  font-weight: 500;
  color: #092a49 !important;
}

.pages-end-para {
  width: 30%;
  font-size: 1.2em;
  font-weight: 400;
  color: #092a49 !important;
  margin-top: 22px;
}

.page-heading {
  line-height: 1.5;
  position: absolute;
  top: 180px;
  left: 200px;
  color: white;
  width: 35%;
  font-size: 65px;
  font-weight: 500;
}
/* .active {
  background-color: none;
  position: fixed;
  top: 0;
  width: 100%;
  visibility: visible;
  transition: all 0.2s;
}

.hidden {
  visibility: hidden;
  transition: all 0.2s;
  transform: translateY(-100);
} */

.nav-link {
  /* color: white !important; */
  font-weight: 500;
  /* font-size: 1.2em; */
}
.nav-link.scrolled{
  color: #092a49 !important;
}

.tab-content {
  padding: 0;
}

.nav.nav-tabs.nav-justified{
  padding-right: 0 !important;
  /* margin-bottom: 3% !important; */
  margin-left: auto !important;
  margin-right: auto !important;
  /* width: 60% !important; */
  /* box-shadow: 0px 8px 10px gray; */
    /* -10px 8px 15px gray, 10px 8px 15px gray; */
}

/* .nav-tabs.nav-item {
  margin-left: 1% !important;
  margin-right: 1% !important;
  /* box-shadow: 1px 3px 9px #000000; */
/* }  */

.nav-tabs .nav-item .nav-link {
  color: gray !important;
  font-size: 20px;
  /* -webkit-box-shadow: 0 -8px 6px -6px gray inset;
	   -moz-box-shadow: 0 -8px 6px -6px gray inset;
	        box-shadow: 0 -8px 6px -6px gray inset; */
  /* box-shadow: 0 3px 5px gray; */
}

.nav-tabs .nav-item .nav-link.active {
  color: #092a49 !important;
  /* text-decoration: underline; */
  /* box-shadow: 1px 3px 8px gray,
  -10px 8px 15px gray, 10px 8px 15px gray; */
  -webkit-box-shadow: 0px -3px 6px gray;
  -moz-box-shadow: 0px -3px 6px gray;
  box-shadow: 0px -3px 6px gray;
  border-bottom: none !important;
  font-size: 20px;
  height: 100%;
}

.nav-link:hover {
  text-decoration: underline;
  color: gray !important;
}

.footer{
  background-color: #092a49;
  margin:0px auto;
  padding: 40px 0px 20px 0px;
}

h4, li {
  color:white;
}

.countup {
  font-size: x-large;
  color:#0000FF;
}

h1, h2, h3 {
  color: #092a49;
  line-height: normal;
}

/* .row.row-content {
  margin-top: 5%;
  margin-bottom: 5%;
} */

.about-image {
  height: 100%;
  width: 100%;
}

.careers-img {
  position: relative;
  background: -webkit-linear-gradient(rgb(0 175 239), rgb(255 255 255));
  
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.apply-btn {
  width: fit-content;
  color:white;
  background-color: #0000FF;
  border: none;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  height:100vh;
}

.resources-para {
  font-size: 5em;
  font-weight: 700;
  color:#092a49;
  position: absolute;
  top: 150px;
  left: 160px;
  width: 50%;
}

.footer-hr {
  color: white;
  margin-top: 3%;
  border-top: 2.5px solid;
  width: 35%
}

/* Cards CSS */

.solutions-card {
  margin-top: 5%;
  box-shadow: none !important;
  border: none !important;
  /* margin-left: 30px; */
  margin-right: 30px;
  margin-bottom: 5%;
}

.vr-line {
  display: inline-block;
  border-left: 2.5px solid #00000A;
  margin: 0 10px;
  height: min-content;
  overflow: hidden;
}

.solutions-page-card {
  box-shadow: none !important;
  margin-left: 30px;
  margin-right: 30px; 
  margin-top: 5%;
  border: none;
}

.services-cards {
  display: flex;
  justify-content: center;
  align-items: center;
}

.products-cards {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ******************* */

.footer-links {
  text-decoration: none;
  color: white;
}
 
.footer-links:hover {
  color: white;
  text-decoration: underline;
}

.loading {
  margin-top: 200px;
  margin-bottom: 200px;
  margin-left: 700px;
}

.product-container {
  margin-left: 0;
  margin-right: 0;
  max-width: max-content;
  background-color: #092a49;
}

.service-container {
  margin-left: 0;
  margin-right: 0;
  max-width: max-content;
  background-color:#092a49;
}

.topbar-links {
  text-decoration: none;
  color: #092a49;
  font-size: 23px;
  font-weight: 500;  
}

.topbar-links:hover {
  text-decoration: none;
  border-bottom: 2px solid #44b5d5;
  padding-bottom: 2px;
  color: #44b5d5;
}

.ibank-video {
  height: 75%;
  width: 100%;
}
@media (min-width: 1024px) and (max-width: 1300px) {
  .brand-logo {
    width: 100px;
  }
  .home-heading{
  top: 130px;
  font-size: 50px;
  }
  .home-unlocked{
    top: 190px;
    font-size: 50px;
    }
    .home-para{
      top: 290px;
    }
    .our-image{
      width: 70%;
    }
  .page-heading {
    top: 160px;
    left: 160px;
    font-size: 60px;
  }
}
@media (min-width: 766px) and (max-width: 1024px) {
  .nav.nav-tabs.nav-justified{
    /* margin-bottom: 3% !important; */
    width: 100% !important;
  }

  .nav-tabs .nav-item .nav-link {
    font-size: 1em;
  }
  
  .nav-tabs .nav-item .nav-link.active {
    /* box-shadow: 1px 3px 8px gray; */
    font-size: 1em;
  }

  .navbar-collapse.show {
    justify-content: center;
    margin-left: 85%;
    padding-right: 2%;
    background-color: #092a49;
  }

  .home-heading {
    position: absolute;
    top: 113px;
    left: 100px;
    font-size: 50px;
    width: 40%;
  }

  .home-fact {
    font-size: 1.2em;
    font-weight: 700; 
    color: #00afef;
  }
  
  .home-qp {
    font-size: 1.5em;
    font-weight: 700;
    color: #00afef;
    margin-right: 3%;
  }
  .circle {
    position: relative;
    /* display: flex; */
    width: 210px;
    height: 210px;
  }

  .circle-text {
    position: absolute;
    top: 31px;
    left: 70px;
  }

  .home-cs {
    font-size: 1.5em;
    color: #092a49;
    font-weight: 700;
  }

  .home-unlocked {
    position: absolute;
    top: 160px;
    left: 100px;
    font-size: 50px;
    width: 40%;
  }

  .home-para {
    position: absolute;
    top: 230px;
    left: 100px;
    color: white;
    font-weight: 300;
    font-size: 18px;
    width: 40%;
  }

  .contact-btn {
    width: fit-content;
    font-size: 1.2em;
  }
  .how-help {
    font-size: 2em;
  }
  .our-impact {
    font-size: 2.5em;
    margin-left: 30%;
  }
  .our-para {
    font-size: 1em;
    margin-left: 30%;
  }
  .our-learn-more {
    border-color: white;
    color: white;
  }
  .our-image {
    object-fit: contain;
    width: 70%;
    /* height: 65%; */
    /* margin-top: 3% !important;*/
    margin-top: 60px;
  }

  .offerings-para {
    font-size: 2.4em;
    font-weight: 500;
  }

  .resources-para {
    font-size: 50px;
    position: absolute;
    top: 150px;
    left: 100px;
  }

  .page-heading {
    line-height: 1.5;
    position: absolute;
    top: 150px;
    left: 150px;
    color: white;
    font-size: 40px;
  }

  .pages-end {
    font-size: 3em;
    font-weight: 500;
  }
  
  .pages-end-para {
    width: 40%;
    font-size: 1.2em;
    font-weight: 400;
  }

  .brand-logo {
    width: 70px;
  }
  
  .solutions-card {
    border: none;
    max-width: fit-content;
    margin-right: 10px;
  }
} 



@media (max-width: 767px) and (min-width: 510px) {
  .navbar {
    background-color: none;
    /* position: absolute; */
    /* top: 0; */
    /* width: 100%; */
  }

  .nav.nav-tabs.nav-justified{
    /* margin-bottom: 3% !important; */
    width: 100% !important;
  }

  .nav-tabs .nav-item .nav-link {
    font-size: 0.8em;
    height: 100%;
  }
  
  .nav-tabs .nav-item .nav-link.active {
    /* box-shadow: 1px 3px 8px gray; */
    font-size: 0.8em;
  }

  .navbar-collapse.show {
    justify-content: center;
    margin-left: 85%;
    padding-right: 2%;
    background-color: #092a49;
  }

  .page-heading {
    position: absolute;
    top: 100px;
    left: 50px;
    color: white;
    font-size: 30px;
  }

  .pages-end {
    font-size: 2.5em;
  }
  
  .pages-end-para {
    width: 50%;
    font-size: 1em;
  }

  .offerings-para {
    display: block;
    overflow: hidden;
    font-size: 2.2em;
    font-weight: 500;
  }

  .brand-logo {
    width: 60px;
  }

  .home-heading {
    position: absolute;
    top: 90px;
    left: 60px;
    color: white;
    font-size: 35px;
  }

  .home-fact {
    font-size: 2em;
    font-weight: 700; 
    color: #00afef;
  }
  .circle {
    position: relative;
    /* display: flex; */
    width: 210px;
    height: 210px;
    margin-bottom: 30%;
  }

  .circle-text {
    position: absolute;
    top: 90px;
    left: 70px;
  }

  .home-qp {
    font-size: 1.5em;
    font-weight: 700;
    color: #00afef;
    /* margin-right: 3%; */
  }

  .home-cs {
    font-size: 1.5em;
  }

  .home-unlocked {
    position: absolute;
    top: 140px;
    left: 60px;
    color: white;
    font-size: 35px;
  }

  .home-para {
    position: absolute;
    top: 180px;
    left: 20px;
    color: white;
    font-weight: 300;
    font-size: 15px;
    width: 50%;
    display: none;
  }

  .contact-btn {
    width: fit-content;
    font-size: 1.2em;
  }
  .how-help {
    font-size: 2em;
  }
  .our-impact {
    font-size: 2em;
    margin-top: 10%;
    margin-left: 10%;
  }
  .our-para {
    font-size: 1em;
    margin-left: 10%;
    /* width: 40%; */
    margin-right: 50px;
  }
  .our-image {
    display: block;
    margin-right: auto !important;
    margin-left: auto !important;
    width: 60%;
    /* height: 50%; */
  }
  .our-learn-more {
    border-color: white;
    color: white;
    margin-left: 10%;
  }
  .our-learn-more:hover {
    border-color: white !important;
    color: white !important;
    /* margin-left: 10%; */
  }

  .resources-para {
    font-size: 2em;
    position: absolute;
    top: 150px;
    left: 120px;
    width: 60%
  }

  .loading {
    margin-top: 150px;
    margin-bottom: 150px;
    margin-left: 150px;
  }

  .learn-more-btn {
    margin-top: 10px;
    margin-bottom: 30px;
    margin-left: auto;
    width: 100%;
  }

  .counter {
    align-items: center;
  }

  .solutions-card {
    display: flex;
    justify-content: center;
    margin-right: auto;
    margin-bottom: 30px;
    width: 100%;
  }
}

@media (max-width: 510px) {
  .navbar-collapse.show {
    justify-content: center;
    margin-left: 80%;
    padding-right: 2%;
    background-color: #092a49;
  }

  .brand-logo {
    width: 100px;
  }

  .home-div {
    max-width: unset;
   height: 440px;
   width:1100px
  }
  .pages-div {
    max-width: unset;
   /* height: 420px; */
   width:998px
  }

  .pages-navbar .navbar-toggler.collapsed {
    justify-content: right;
    /* padding-right: 2%; */
    /* background-color: #092a49; */
  }

  .offerings-para {
    display: block;
    overflow: hidden;
    /* padding-left: 10%; */
    font-size: 2.1em;
    font-weight: 500;
  }

  .nav.nav-tabs.nav-justified{
    /* margin-bottom: 3% !important; */
    width: 100% !important;
  }

  .nav-tabs .nav-item .nav-link {
    color: gray !important;
    font-size: 0.8em;
    /* box-shadow: 0 3px 5px gray; */
  }
  
  .nav-tabs .nav-item .nav-link.active {
    color: #092a49 !important;
    text-decoration: underline;
    /* box-shadow: 0 3px 5px gray; */
    font-size: 0.8em;
  }
  .home-heading {
    position: absolute;
    top: 194px;
    left: 36px;
    color: white;
    font-size: 35px;
  }

  .home-fact {
    font-size: 2em;
  }

  .circle {
    left: 75px;
    position: relative;
    /* display: flex; */
    width: 180px;
    height: 180px;
    margin-bottom: 40%;
  }

  .circle-text {
    position: absolute;
    top: 12px;
    left: 64px;
  }

  .home-qp {
    font-size: 1.5em;
  }

  .home-cs {
    font-size: 1.5em;
  }

  .home-unlocked {
    position: absolute;
    top: 242px;
    left: 36px;
    color: white;
    font-size: 35px;
  }

  .home-para {
    display: none;
  }

  .contact-btn {
    width: fit-content;
    font-size: 1em;
  }
  .how-help {
    font-size: 1.5em;
  }
  .our-impact {
    font-size: 2em;
    margin-top: 10%;
    margin-left: 10%;
  }
  .our-para {
    font-size: 1em;
    margin-left: 10%;
    margin-right: 10%;
  }
  .our-image {
    display: block;
    margin-right: auto !important;
    margin-left: auto !important;
    object-fit: contain;
    width: 70%;
    /* height: 58%; */
  }
  .our-learn-more {
    margin-left: 10%;
  }

  .resources-para {
    font-size: 2em;
    position: absolute;
    top: 140px;
    left: 60px;
    width: 60%;
  }

  .page-heading {
    line-height: 1.5;
    position: absolute;
    top: 166px;
    left: 36px;
    color: white;
    font-size: 30px;
    width: 50%;
  }

  .pages-end {
    font-size: 2em;
  }
  
  .pages-end-para {
    width: 70%;
    font-size: 1em;
  }
}

@media screen and (max-width: 768px) {
  .nav-link {
    color: #000000 !important;
    
  }
}
/* .nav-item > a.dropdown-toggle::after{
  display: none;
} */
.nav-item > .dropdown-menu{
  left: auto;
  right: 0px;
}
.navbar-light .navbar-toggler{
  border: none;
}
.nav-item > .dropdown-toggle{
 color: transparent !important;
 font-size: 30px;
}